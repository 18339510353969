.budget-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.budget-table th, .budget-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.budget-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.budget-table tbody tr:hover {
    background-color: #f1f1f1;
}

.category-cell {
    position: relative;
}

.category-tooltip {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 10;
    top: 100%;
    left: 0;
    width: 300px;
    display: flex;
    flex-direction: column;
}

.category-item {
    margin-bottom: 5px;
}

.category-item:last-child {
    margin-bottom: 0;
}