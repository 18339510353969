/* Navbar */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2); 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    /* z-index: 1; */
    padding: 0px 10px 0px 2px;
    color: #fff;
    border-radius: 10px 0 0 0px;
    overflow: hidden;
}

.navbar-logo {
    position: relative;
}

.navbar-logo img {
    width: 154px;
    height: 120px;
    border-radius: 10px 0 0 0px;
    border-right: 1px solid rgba(255, 255, 255, 0.2); 
    display: flex;
    justify-content: center;
}

.navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    margin-right: 10px;
    padding: 0;
}

.navbar-links li {
    margin-left: 20px;
}

.navbar-links a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    padding: 10px;
    display: block;
    border-radius: 10px;
}

.navbar-links a:hover {
    background-color: rgba(35, 20, 59, 0.5); 
    border-radius: 10px;
}



/* Sidebar */

/* Sidebar */

.sidebar {
    width: 11.5vw;
    background: rgba(255, 255, 255, 0.1); 
    backdrop-filter: blur(10px); 
    border-right: 1px solid rgba(255, 255, 255, 0.2); 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 100vh;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    left: 0;
}

.sidebar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.sidebar-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-nav li {
    margin: 10px;
    padding: 15px;
    gap: 20px;
    cursor: pointer;
}

.nav-icon {
    font-size: 40px;
    color: #fff;
}

.nav-item.active .nav-icon {
    color: #130F29;
}