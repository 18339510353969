.transaction-modal {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
    overflow-y: auto;
    overflow-x: hidden;
}

.form-type-selector {
    display: flex;
    text-align: center;
    flex-direction: row;
    margin-bottom: 1.5rem;
}

.form-type-selector button {
    width: 100%;
}

.transaction-form{
    width: 50%;
    /* overflow-y: auto; */
}

.btn-close {
    margin-top: 1rem;
    background-color: red;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
}

label{
    color: #333;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.form-group button{
    width: 50%;
    padding: 3px 0;
    font-size: 12px;
}

input[type="text"],
input[type="number"] {
    width: auto;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
}

select {
    width: 100%;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
}

.form-group.account{
    gap: 5px;
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: center;
}

.form-group.account .account-selction{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-group.budget{
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: center;
}

.form-group.budget .budget-selction{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-group.category{
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: center;
}

.form-group.date{
    gap: 5px;
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    flex-direction: row;
}

.form-group.date input[type="date"]{
    width: 100%;
    padding: 5px;
    border-radius: 4px;
}

.form-group.amount{
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: center;
}

.form-group.description{
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: center;
}

.form-group.source{
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;
    justify-content: center;
}

.form-group.type{
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;
    justify-content: center;
}

.form-group.status{
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;
    justify-content: center;
}