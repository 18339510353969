/* Key metrics css */

.key-metrics {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.metric-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.metric-card h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
}

.metric-card p {
    font-size: 1.5em;
    font-weight: bold;
}

.kpi-metrics p{
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    background-color: gainsboro;
    padding: 10px;
}

/* Financial Health Indicators */

.financial-health-indicators {
    background: rgba(156, 163, 175, 1);
    padding: 20px;
    border-radius: 10px;
    border: 5px solid rgba(17, 24, 39, 1);
}

.health-indicators {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.indicator-card {
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.4),rgba(255, 255, 255, 0)) ;
    box-shadow:  1px 4px 15px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 8px;
    padding: 20px;
}

.indicator-card h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
}

.indicator-card p {
    font-size: 1.5em;
    font-weight: bold;
}


/* Insights */

.insights-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 20px 20px;
    margin-top: 10px;
    border: 5px solid white;
    background: rgba(17, 24, 39, 1);
    border-radius: 10px;
}

.insights-page h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: white;
}

.insights-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.insight-card {
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.4),rgba(255, 255, 255, 0)) ;
    box-shadow:  1px 4px 15px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 8px;
    padding: 20px;
}