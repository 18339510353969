/* Export */

.export-options {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
}

.export-options button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #5e656d;
    color: #fff;
    cursor: pointer;
}