.cards-container{
  display: grid;
  column-gap: 10px;
  grid-template-columns: auto auto;
}

.card-content{
  display: flex;
  flex-direction: row;
}

.metric-card{
  margin: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between; 
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  backdrop-filter: blur(2.7px);
  -webkit-backdrop-filter: blur(2.7px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.56);
}

.col-1, .col-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin: 10px;
}

.info-card{
  padding: 20px;
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between; 
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  backdrop-filter: blur(2.7px);
  -webkit-backdrop-filter: blur(2.7px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.56);
}

.info-card button{
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: none;
}